<template>
	<div>
		<div class="sm:flex items-center m-auto text-center sm:text-left">
			<h2>
				{{ $t("Bienvenue sur le logiciel de gestion") }}
				{{ invite && invite.app.organization.name ? `de ${invite.app.organization.name}` : "SINAO" }} !
			</h2>
		</div>

		<div v-if="invite" class="sm:flex items-center m-auto text-center sm:text-left mb-5">
			<h4>Vous avez été invité par</h4>
			<div class="w-full sm:w-1/2 flex items-center ml-0 sm:ml-4 mt-2 sm:mt-0 justify-center sm:justify-start">
				<img :src="getImageUrl(invite.sender_user)" class="rounded-full shadow-md" height="50px" width="50px" />
				<h4 class="ml-2 title-sinao">{{ invite.sender_user.firstname }} {{ invite.sender_user.lastname }}</h4>
			</div>
		</div>

		<!-- Errors -->
		<vs-alert v-if="returnError" class="mt-3 mb-5" :title="returnError.title">
			{{ returnError.text }}
		</vs-alert>

		<vs-button
			v-if="returnError && returnError.code === 404"
			class="w-full vs-con-loading__container mb-5"
			color="var(--primary)"
			@click="goToLogin"
		>
			{{ $t("Retour à la connexion") }}
		</vs-button>

		<vs-card v-if="invite" class="shadow-none border">
			<div class="pb-35 text-center">
				<h4>{{ $t("Veuillez finaliser votre inscription") }}</h4>
			</div>

			<!-- Form -->
			<div class="vx-row mb-3">
				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						v-model="firstname"
						name="prenom"
						color="var(--black)"
						class="w-full"
						:placeholder="$t('Prénom')"
						label-placeholder="Prénom"
					/>
				</div>
				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						v-model="lastname"
						name="nom"
						color="var(--black)"
						class="w-full"
						:placeholder="$t('Nom')"
						label-placeholder="Nom"
					/>
				</div>
			</div>

			<div class="vx-row">
				<div class="vx-col w-full my-3">
					<vs-input
						:value="email"
						disabled
						name="email"
						color="var(--black)"
						class="w-full"
						:placeholder="$t('Email')"
					/>
				</div>
			</div>

			<div class="vx-row mt-3">
				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						ref="password"
						v-model="password"
						v-validate="'required|min:8'"
						name="password"
						color="var(--black)"
						type="password"
						:placeholder="$t('Mot de passe')"
						class="w-full"
					/>
					<span v-show="errors.has('password')" class="text-danger text-sm">{{
						errors.first("password")
					}}</span>
				</div>

				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						v-model="password2"
						v-validate="'required|confirmed:password'"
						name="password2"
						color="var(--black)"
						type="password"
						data-vv-as="password"
						:placeholder="$t('Confirmation')"
						class="w-full"
						@keypress.enter="validate"
					>
						{{ $t("Confirmer mot de passe") }} :
					</vs-input>
					<span v-show="errors.has('password2')" class="text-danger text-sm">
						{{ errors.first("password2") }}
					</span>
				</div>
			</div>

			<!-- CGU -->
			<div class="flex flex-col content-start mt-3 px-3">
				<label for="cgu">
					<input id="cgu" v-model="cgu" v-validate="'required'" name="cgu" type="checkbox" />
					{{ $t("J'accepte les") }}
					<a href="https://sinao.fr/cgu/" target="blank" class="d-block">
						{{ $t("Conditions Générales d'Utilisation") }}
					</a>
				</label>

				<span v-show="errors.has('cgu')" class="text-danger text-sm">{{ errors.first("cgu") }}</span>
			</div>

			<!-- soumission du fomulaire -->
			<div class="vx-row pt-35">
				<div class="vx-coll w-1/2 px-3">
					<s-button type="border" class="w-full" @click="confirmeCancelInvit = true">
						{{ $t("Décliner l'invitation") }}
					</s-button>
				</div>
				<div class="vx-col w-1/2 px-3">
					<s-button
						ref="submit"
						type="success"
						class="w-full vs-con-loading__container"
						:disabled="!complete && disabled"
						@click="validate"
					>
						{{ $t("Terminer") }}
					</s-button>
				</div>
			</div>

			<!-- popup décliner l'invit -->
			<vs-popup :active.sync="confirmeCancelInvit" :title="$t('Confirmer le rejet de l invitation')">
				{{ $t("Etes vous sûr de vouloir décliner l'invitation ?") }}
				<div class="vx-col mt-3 flex flex-row-reverse justify-end">
					<s-button class="mx-3" type="success" @click="cancel"> {{ $t("Oui") }} </s-button>
					<s-button class="mx-3" type="border" @click="confirmeCancelInvit = false">
						{{ $t("Non") }}
					</s-button>
				</div>
			</vs-popup>
		</vs-card>
	</div>
</template>

<script>
	import { Validator } from "vee-validate";
	import { mapGetters } from "vuex";
	import i18n from "../i18n";

	// dictionnaire des messages d'erreur inputs formulaire
	const dict = {
		custom: {
			firstname: {
				required: () => i18n.t("Veuillez entrer votre prénom")
			},
			lastname: {
				required: () => i18n.t("Veuillez entrer votre nom")
			},
			password: {
				required: () => i18n.t("Veuillez entrer votre mot de passe"),
				min: () => i18n.t("Veuillez entrer 8 caractères minimum")
			},
			password2: {
				required: () => i18n.t("Veuillez confirmer votre mot de passe"),
				confirmed: () => i18n.t("Les mots de passe ne correspondent pas")
			},
			cgu: {
				required: () => i18n.t("Vous devez accepter les CGU")
			}
		}
	};

	// appel du dictionnaire pour la validation formulaire
	Validator.localize("fr", dict);

	export default {
		data() {
			return {
				// Invitation
				invite: null,
				// User inputs
				firstname: "",
				lastname: "",
				password: "",
				password2: "",
				cgu: false,
				// From from the URL
				token: this.$route.params.tokenURL,
				// Modal
				confirmeCancelInvit: false,
				// Errors management
				disabled: false,
				returnError: null,
				apiErrors: {
					get: {
						404: {
							code: 404,
							title: "L'invitation que vous essayez d'utiliser n'existe pas.",
							text: i18n.t(
								"Vérifiez que l'on vous a transmis le lien de cette invitation en entier et/ou que vous l'avez copié en entier."
							)
						},
						400: {
							code: 400,
							title: "L'invitation que vous essayez d'utiliser n'est pas valide",
							text: i18n.t("Vérifiez l'integrité du lien que vous avez suivi.")
						},
						default: {
							code: "default",
							title: "Erreur inconnue",
							text: i18n.t("Une erreur est survenue, veuillez nous excuser.")
						}
					},
					post: {
						400: {
							code: 400,
							title: "Requete invalide",
							text: i18n.t(
								"Une erreur est survenue, cela ne vivient propablement pas de vous, veuillez nous excuser."
							)
						},
						404: {
							code: 404,
							title: "L'invitation que vous essayez d'utiliser n'existe plus.",
							text: i18n.t("Demander a ce que votre invitation soit renouvellée.")
						},
						401: {
							code: 401,
							title: "L'invitation que vous essayez d'utiliser a expirée.",
							text: i18n.t("Demander a ce que votre invitation soit renouvellée.")
						},
						default: {
							code: "default",
							title: "Erreur inconnue",
							text: i18n.t("Une erreur est survenue, veuillez nous excuser.")
						}
					}
				}
			};
		},
		computed: {
			...mapGetters(["getImageUrl"]),
			complete() {
				return this.password && this.password2 && this.cgu;
			}
		},
		async created() {
			try {
				this.invite = await this.$store.dispatch("getInvite", this.token);

				const expirationTimestamp = new Date(this.invite.created_at).getTime() + 30 * 24 * 60 * 60 * 1000;

				if (expirationTimestamp - new Date().getTime() <= 0) {
					this.getError("post", 401);
				}
			} catch (error) {
				this.getError("get", error?.response?.status);
			}

			if (this.invite) {
				const { firstname, lastname, email } = this.invite;

				this.firstname = firstname;
				this.lastname = lastname;
				this.email = email;
			}
		},
		methods: {
			getError(method, status) {
				this.returnError = this.apiErrors[method][status] ?? this.apiErrors[method]["default"];
			},
			async validate() {
				const isFormValid = await this.$validator.validateAll();

				if (isFormValid) {
					const { firstname, lastname, email, password, token, cgu } = this;

					this.disabled = true;

					this.$vs.loading({
						type: "default",
						color: "var(--white)",
						background: "var(--primary)",
						container: this.$refs.submit.$el,
						scale: 0.5
					});

					this.$store
						.dispatch("createInvite", {
							firstname,
							lastname,
							email,
							password,
							token,
							cgu: cgu ? 1 : 0
						})
						.then(() => {
							this.$store
								.dispatch("login", {
									email,
									password
								})
								.then(() => {
									this.disabled = false;

									this.$vs.loading.close(this.$refs.submit.$el);

									this.$vs.notify({
										title: "Inscription réussie!",
										text: i18n.t(
											"Votre inscription a bien été enregistrée, vous allez être redirigé vers la liste de vos entreprises."
										),
										color: "success",
										iconPack: "feather",
										icon: "icon-check",
										time: 5000
									});

									this.$router.push({ name: "Apps" });
								})
								.catch(error => {
									console.log(error);

									this.disabled = false;
									this.$vs.loading.close(this.$refs.submit.$el);
									this.getError("post", error?.response?.status);
								});
						})
						.catch(error => {
							console.log(error);

							this.disabled = false;
							this.$vs.loading.close(this.$refs.submit.$el);
							this.getError("post", error?.response?.status);
						});
				}
			},
			cancel() {
				this.$store
					.dispatch("deleteInviteByToken", this.token)
					.then(() => {
						this.confirmeCancelInvit = false;
						this.$router.push("/");
					})
					.catch(error => {
						console.log(error);

						this.getError("post", error?.response?.status);

						this.confirmeCancelInvit = false;

						this.$vs.notify({
							title: "Impossible de rejeter l'invitation",
							text: i18n.t("L'invitation n'a pas pu etre rejetée. Raison : ...", { error: error }),
							color: "success",
							iconPack: "feather",
							icon: "icon-check",
							time: 5000
						});
					});
			},
			goToLogin() {
				this.$router.push({ name: "Login" });
			}
		}
	};
</script>

<style>
	div.vs-card--content {
		padding: 35px !important;
	}

	.pb-35 {
		padding-bottom: 35px;
	}
	.pt-35 {
		padding-top: 35px;
	}
</style>
